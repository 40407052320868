import React, { FC } from 'react'
import { useFragment, graphql } from 'react-relay'

import { Stack } from '../Stack'
import { Text } from '../Text'

import {
  GuildMembershipStatus_guild$key,
  GuildMembershipStatus_guild,
} from './__generated__/GuildMembershipStatus_guild.graphql'

export const GuildMembershipStatus: FC<{
  guild: GuildMembershipStatus_guild$key
  displayMembershipStatus: boolean
}> = ({ guild, displayMembershipStatus }) => {
  const data = useFragment(
    graphql`
      fragment GuildMembershipStatus_guild on Guild
      @argumentDefinitions(
        displayMembershipStatus: { type: "Boolean!", defaultValue: true }
      ) {
        myMembership @include(if: $displayMembershipStatus) {
          membershipLevel
        }

        networkMembers {
          totalCount
        }
      }
    `,
    guild
  )

  if (!data.myMembership && !data.networkMembers.totalCount) {
    return null
  }

  return (
    <Stack direction='horizontal' gap={2}>
      {data.networkMembers.totalCount > 0 && (
        <Text size={4}>
          {data.networkMembers.totalCount}{' '}
          {data.networkMembers.totalCount === 1 ? 'member' : 'members'}
        </Text>
      )}

      {displayMembershipStatus &&
        data.networkMembers.totalCount > 0 &&
        data.myMembership && <Text size={4}>•</Text>}

      {displayMembershipStatus && <GuildMembershipLevel guild={data} />}
    </Stack>
  )
}

const GuildMembershipLevel: FC<{ guild: GuildMembershipStatus_guild }> = ({
  guild,
}) => {
  if (!guild.myMembership) {
    return null
  }

  switch (guild.myMembership.membershipLevel) {
    case 'ORGANIZER':
      return <Text size={4}>Organizer</Text>
    case 'MEMBER':
      return <Text size={4}>Member</Text>
    case 'EDITOR':
      return <Text size={4}>Editor</Text>
    default:
      console.error('UNKNOWN GUILD MEMBERSHIP LEVEL', guild)
      return null
  }
}
