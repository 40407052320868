/**
 * @generated SignedSource<<fed9dc28d9bf50da87c151e2c4b3043e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GuildMembershipStatus_guild$data = {
  readonly myMembership?: {
    readonly membershipLevel: GuildMembershipLevel;
  } | null | undefined;
  readonly networkMembers: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "GuildMembershipStatus_guild";
};
export type GuildMembershipStatus_guild$key = {
  readonly " $data"?: GuildMembershipStatus_guild$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuildMembershipStatus_guild">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "displayMembershipStatus"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuildMembershipStatus_guild",
  "selections": [
    {
      "condition": "displayMembershipStatus",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GuildMembership",
          "kind": "LinkedField",
          "name": "myMembership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "membershipLevel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UsersConnection",
      "kind": "LinkedField",
      "name": "networkMembers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Guild",
  "abstractKey": null
};

(node as any).hash = "762ab0c0db8c1f4c90451af79540986e";

export default node;
