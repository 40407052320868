/**
 * @generated SignedSource<<cdce5f4745538f10aba9e7cd59776279>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
export type GuildTypes = "GUILD" | "NETWORK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GuildCard_guild$data = {
  readonly backgroundPhoto: {
    readonly " $fragmentSpreads": FragmentRefs<"GraphQLImage_image">;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly myMembership: {
    readonly membershipLevel: GuildMembershipLevel;
  } | null | undefined;
  readonly name: string;
  readonly primaryPhoto: {
    readonly id: string;
  } | null | undefined;
  readonly rowId: String;
  readonly slugId: string;
  readonly type: GuildTypes | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GuildJoinConfirmationContent_guild" | "GuildMembershipStatus_guild" | "GuildPrimaryPhoto_guild">;
  readonly " $fragmentType": "GuildCard_guild";
};
export type GuildCard_guild$key = {
  readonly " $data"?: GuildCard_guild$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuildCard_guild">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuildCard_guild",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slugId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GuildMembership",
      "kind": "LinkedField",
      "name": "myMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membershipLevel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuildMembershipStatus_guild"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuildJoinConfirmationContent_guild"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuildPrimaryPhoto_guild"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "primaryPhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "backgroundPhoto",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GraphQLImage_image"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Guild",
  "abstractKey": null
};

(node as any).hash = "d0abaa71ac41182061b597259b298680";

export default node;
