import React, { FC, useMemo, forwardRef, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { Location, useLocation, useMatch, useNavigate } from 'react-router-dom'

import { Button } from '../Button'
import { Element } from '../Element'
import { FormattedMarkdown } from '@newComponents'
import { Link } from '../Link'
import { LinkText, Text } from '../Text'
import { Stack } from '../Stack'
import { GraphQLImage, ImageKitImage } from '../GraphQLImage'
import { Icon } from '../Icon'
import { useInEmbed } from '../Embed'
import { GuildPrimaryPhoto } from './GuildPrimaryPhoto'

import { GuildMembershipStatus } from './GuildMembershipStatus'
import { useAuthContext } from '@data/useAuthContext'

import {
  GuildCard_guild,
  GuildCard_guild$key,
} from './__generated__/GuildCard_guild.graphql'
import { useAnalyticsContext } from '@data/Analytics'

interface GuildCardProps {
  guild: GuildCard_guild$key
  expand?: boolean
  limitDescriptionLines?: number
  primaryButton?: boolean
  showSettingsButton?: boolean
}

/*
 * Guild card descriptions not supporting emphasis and bold formatting for now
 */
const ALLOWED_ELEMENTS = ['a', 'p']

const BACKGROUNDS = [
  'https://ik.imagekit.io/guild/social-card-backgrounds/purple-yellow_zbyzdUESq.png',
  'https://ik.imagekit.io/guild/social-card-backgrounds/pink-yellow_UvKUtm5f3.png',
  'https://ik.imagekit.io/guild/social-card-backgrounds/blue-purple_F4x1_B8WX.png',
  'https://ik.imagekit.io/guild/social-card-backgrounds/blue-teal_HwODg9qh1.png',
  'https://ik.imagekit.io/guild/social-card-backgrounds/purple-blue_t3kh8e78Bl.png',
]

export const GuildCard: FC<GuildCardProps> = forwardRef(
  (
    {
      guild,
      expand = false,
      limitDescriptionLines,
      primaryButton = false,
      showSettingsButton = false,
    },
    ref
  ) => {
    const data = useFragment(
      graphql`
        fragment GuildCard_guild on Guild {
          slugId
          rowId

          name
          description

          type

          myMembership {
            membershipLevel
          }

          ...GuildMembershipStatus_guild
          ...GuildJoinConfirmationContent_guild

          ...GuildPrimaryPhoto_guild

          primaryPhoto {
            id
          }
          backgroundPhoto {
            ...GraphQLImage_image
          }
        }
      `,
      guild
    )

    const isGuildScreen = useMatch(`/${data.slugId}/*`)

    return (
      <Stack expand={expand}>
        <Element
          component='ActorCard'
          variant={
            !data.primaryPhoto && !data.backgroundPhoto ? 'noPhotos' : undefined
          }
          style={{ flex: 1 }}
          ref={ref}
        >
          <Link to={`/${data.slugId}`}>
            <GuildCardPhotos guild={data} />
          </Link>
          <Stack gap={4} expand={expand}>
            <Stack expand={expand}>
              <Element component='ActorCardInfo'>
                <Stack gap={4} expand={expand}>
                  <Stack gap={2} expand={expand}>
                    <Link to={`/${data.slugId}`}>
                      <Text size={6} numberOfLines={1} variant='heading'>
                        {data.name}
                      </Text>
                    </Link>

                    <GuildMembershipStatus
                      guild={data}
                      displayMembershipStatus={true}
                    />

                    {!!data.description &&
                      (isGuildScreen ? (
                        <FormattedMarkdown
                          allowedElements={ALLOWED_ELEMENTS}
                          formattedVariant='PLAIN_TEXT'
                          numberOfLines={limitDescriptionLines}
                          textSize='$4'
                        >
                          {data.description}
                        </FormattedMarkdown>
                      ) : (
                        <Link to={`/${data.slugId}`}>
                          <FormattedMarkdown
                            allowedElements={ALLOWED_ELEMENTS}
                            formattedVariant='PLAIN_TEXT'
                            numberOfLines={limitDescriptionLines}
                            textSize='$4'
                          >
                            {data.description}
                          </FormattedMarkdown>
                        </Link>
                      ))}
                  </Stack>

                  <MembershipButton
                    guild={data}
                    primaryButton={primaryButton}
                  />
                </Stack>
              </Element>
            </Stack>

            {data.myMembership?.membershipLevel === 'ORGANIZER' &&
              showSettingsButton && <SettingsButton guild={data} />}
          </Stack>
        </Element>
      </Stack>
    )
  }
)

const GuildCardPhotos: FC<{
  guild: GuildCard_guild
}> = ({ guild }) => {
  const defaultBackground = useMemo(() => {
    const shortRowId = guild.rowId.substring(0, 8) || '0'

    return BACKGROUNDS[parseInt(shortRowId, 16) % BACKGROUNDS.length]
  }, [guild.backgroundPhoto, guild.rowId])

  if (!guild.primaryPhoto && !guild.backgroundPhoto) {
    return null
  }

  return (
    <Element component='ActorCardPhotos' variant='hasBackgroundPhoto'>
      {guild.backgroundPhoto ? (
        <GraphQLImage
          component='ActorCardBackgroundPhoto'
          image={guild.backgroundPhoto}
          maxWidth='max'
          maxHeight={30}
        />
      ) : (
        <ImageKitImage
          component='ActorCardBackgroundPhoto'
          imageUrl={defaultBackground}
          maxWidth='max'
          // maxHeight={30}
          transformations={{
            ar: '2-1',
          }}
        />
      )}

      {guild.primaryPhoto ? (
        <Element tokens={{ marginTop: -10 }}>
          <GuildPrimaryPhoto guild={guild} variant='inCard' size={20} />
        </Element>
      ) : null}

      {/* TODO: Make Network badge work when there's not Primary & Background photos */}
      {!!(
        guild.type === 'NETWORK' &&
        guild.primaryPhoto &&
        guild.backgroundPhoto
      ) && (
        <Element
          tokens={{
            marginTop: -8,
            paddingLeft: 28,
            paddingBottom: 2,
            alignItems: 'flex-start',
          }}
        >
          <Text size={4} variant='backgroundHighlightDark'>
            Network
          </Text>
        </Element>
      )}
    </Element>
  )
}

const MembershipButton: FC<{
  guild: GuildCard_guild
  primaryButton?: boolean
}> = ({ guild, primaryButton }) => {
  const { accessToken } = useAuthContext()

  const navigate = useNavigate()
  const location = useLocation()

  const analytics = useAnalyticsContext()

  const inEmbed = useInEmbed()

  if (!guild.myMembership && !inEmbed) {
    return (
      <Button
        variant={primaryButton ? undefined : 'secondary'}
        hoverVariant={primaryButton ? 'hover' : 'secondaryHover'}
        onPress={async () => {
          if (accessToken) {
            navigate(`/${guild.slugId}/confirm`)
            await analytics.trackEvent('1DBCTRQ4')
          } else {
            navigate('/sign-up', {
              state: {
                backgroundLocation:
                  // @ts-expect-error react router state is untyped
                  (location.state?.backgroundLocation as Location) || location,
                authRedirectPath: `/${guild.slugId}/confirm`,
              },
            })
          }
        }}
      >
        <Text variant={primaryButton ? 'button' : 'buttonSecondary'} size={4}>
          Join
        </Text>
      </Button>
    )
  }

  return (
    <Link to={`/${guild.slugId}`}>
      <Button variant='secondary' hoverVariant='secondaryHover'>
        <Text variant='buttonSecondary' size={4}>
          View Guild
        </Text>
      </Button>
    </Link>
  )
}

const SettingsButton: FC<{ guild: GuildCard_guild }> = ({ guild }) => {
  const [buttonHovered, setButtonHovered] = useState(false)

  return (
    <Link to={`/${guild.slugId}/settings`}>
      <Button
        variant='callToAction'
        hoverVariant='callToActionHover'
        onHoverIn={() => setButtonHovered(true)}
        onHoverOut={() => setButtonHovered(false)}
      >
        <Stack
          direction='horizontal'
          gap={2}
          justifyContent='center'
          alignItems='center'
        >
          <Icon
            name='settings-filled'
            variant={buttonHovered ? undefined : 'callToActionButton'}
            size={4}
          />

          <Text
            variant={buttonHovered ? 'subHeading' : 'callToActionButton'}
            size={4}
          >
            Settings
          </Text>
        </Stack>
      </Button>
    </Link>
  )
}
