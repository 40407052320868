import React, { FC, useEffect, useRef, useState, useContext } from 'react'
import { View } from 'react-native'
import { useFragment, graphql } from 'react-relay'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  interpolate,
  Extrapolate,
  Easing,
  withTiming,
  withSpring,
} from 'react-native-reanimated'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
// import { lighten } from 'polished'

import {
  Breakpoint,
  Button,
  Card,
  Column,
  EmojiIcon,
  EventCard,
  PresentationItem,
  Grid,
  GuildCard,
  GuildPrimaryPhoto,
  Image,
  Link,
  LinkText,
  ExternalLink,
  PresentationCard,
  Screen,
  Stack,
  StackedCarousel,
  Text,
  HorizontalLine,
  useBreakpoint,
} from '@components'

import { EventItem } from '@newComponents'

import { selectBreakpointToken } from '@components/util/selectBreakpointToken'

import { TokensContext } from '@components/context'

import { HomeScreenEventsCard } from './HomeScreenEventsCard'
import { HomeScreenPresentationsCard } from './HomeScreenPresentationsCard'
import { HomeScreenFeaturesCard } from './HomeScreenFeaturesCard'
import { HomeScreenGuildNetworksCard } from './HomeScreenGuildNetworksCard'

import { HomeScreenGradient } from './HomeScreenGradient'

import { UnauthenticatedHomeScreen_query$key } from './__generated__/UnauthenticatedHomeScreen_query.graphql'

export const UnauthenticatedHomeScreen: FC<{
  query: UnauthenticatedHomeScreen_query$key
}> = ({ query }) => {
  const data = useFragment(
    graphql`
      fragment UnauthenticatedHomeScreen_query on Query {
        ...HomeScreenPresentationsCard_query
        ...EventItemNew_query

        nodeBySlugId(id: $highlightedGuildSlug) {
          ... on Guild {
            ...GuildCard_guild

            upcomingEvents: eventsByTimeSelection(
              first: 1
              timeSelection: UPCOMING
              orderBy: [START_AT_ASC, ID_ASC]
            ) {
              edges {
                node {
                  ...EventItemNew_event
                }
              }
            }

            pastEvents: eventsByTimeSelection(
              first: 1
              timeSelection: PAST
              orderBy: [START_AT_DESC, ID_ASC]
            ) {
              edges {
                node {
                  ...EventItemNew_event
                }
              }
            }

            upcomingPresentations: presentationsByEventTimeSelection(
              first: 1
              orderBy: [CREATED_AT_ASC, ID_ASC]
              timeSelection: UPCOMING
            ) {
              edges {
                node {
                  ...PresentationItem_presentation
                }
              }
            }

            pastPresentations: presentationsByEventTimeSelection(
              first: 1
              orderBy: [CREATED_AT_DESC, ID_ASC]
              timeSelection: OTHER
            ) {
              edges {
                node {
                  ...PresentationItem_presentation
                }
              }
            }
          }
        }

        rankedGuilds(first: 5, guildType: GUILD) {
          edges {
            node {
              name
              slugId
              type

              ...GuildPrimaryPhoto_guild
              ...GuildCard_guild
            }

            cursor
          }
        }
      }
    `,
    query
  )

  const breakpoint = useBreakpoint()

  const tokensContext = useContext(TokensContext)

  // const perspectiveAnimation = useSharedValue(1000)
  const rotateAnimation = useSharedValue(35)
  const translateYAnimation = useSharedValue(100)
  const opacityAnimation = useSharedValue(0)
  // const scrollY = useSharedValue(0)
  // const windowHeight = useSharedValue(0)

  const guildCardTranslateZAnimation = useSharedValue(0)
  const guildCardTranslateXAnimation = useSharedValue(0)
  const guildCardTranslateYAnimation = useSharedValue(0)

  const eventCardTranslateZAnimation = useSharedValue(0)
  const eventCardTranslateXAnimation = useSharedValue(0)

  const guildCardRef = useRef()
  const gradientContainerRef = useRef()

  const [gradient, setGradient] = useState<Gradient | null>(null)

  useEffect(() => {
    if (
      gradientContainerRef.current &&
      gradient &&
      typeof window !== 'undefined' &&
      window.IntersectionObserver
    ) {
      const intersectionObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            gradient.play()
          } else {
            gradient.pause()
          }
        }
      )

      intersectionObserver.observe(gradientContainerRef.current)

      return () => {
        intersectionObserver.disconnect()
      }
    }
  }, [gradient])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.IntersectionObserver) {
      if (guildCardRef.current) {
        const springOptions = { mass: 1, stiffness: 50, damping: 10 }

        let observerConnected = true

        const intersectionObserver = new window.IntersectionObserver(
          ([entry]) => {
            if (entry.intersectionRatio >= 0.4) {
              rotateAnimation.value = withSpring(
                selectBreakpointToken(breakpoint, [35, 35, 25]),
                springOptions
              )
              translateYAnimation.value = withSpring(
                selectBreakpointToken(breakpoint, [-350, -350, -100]),
                springOptions
              )
              opacityAnimation.value = withSpring(1.0, {
                ...springOptions,
                overshootClamping: true,
              })

              guildCardTranslateZAnimation.value = withSpring(
                100,
                springOptions
              )
              guildCardTranslateXAnimation.value = withSpring(
                selectBreakpointToken(breakpoint, [-25, -25, -25, -35]),
                springOptions
              )
              guildCardTranslateYAnimation.value = withSpring(
                selectBreakpointToken(breakpoint, [-30, -30, -30, -75]),
                springOptions
              )

              eventCardTranslateZAnimation.value = withSpring(
                100,
                springOptions
              )
              eventCardTranslateXAnimation.value = withSpring(
                selectBreakpointToken(breakpoint, [25, 25, 35, 50, 60]),
                springOptions
              )

              intersectionObserver.disconnect()
              observerConnected = false
            }
          },
          { threshold: [0, 0.4, 1.0] }
        )

        intersectionObserver.observe(guildCardRef.current)

        return () => {
          if (observerConnected) {
            intersectionObserver.disconnect()
          }
        }
      }
    }
  }, [guildCardRef, breakpoint])

  const cardStyle = useAnimatedStyle(() => {
    return {
      paddingHorizontal: 40,

      transform: [
        { perspective: 1000 },
        { rotateX: `${rotateAnimation.value}deg` },
        { translateZ: -250 },
        {
          translateY: translateYAnimation.value,
        },
      ],
      opacity: opacityAnimation.value,
    }
  }, [])

  const bigCardStyle = useAnimatedStyle(() => {
    return {
      transformOrigin: 'top',
      backgroundColor: '#FFFFFF',
      paddingTop: 32,
      paddingBottom: 16,
      paddingHorizontal: 16,

      borderRadius: 32,

      shadowColor: 'rgba(10, 37, 64, 0.08)',
      shadowRadius: 12,

      transform: [
        { perspective: 1000 },
        { rotateX: `${rotateAnimation.value}deg` },
        { translateZ: -250 },
        {
          translateY: translateYAnimation.value,
        },
      ],
      opacity: opacityAnimation.value,
    }
  }, [])

  const guildCardStyle = useAnimatedStyle(() => {
    return {
      borderRadius: 32,
      overflow: 'hidden',
      zIndex: 2,

      transform: [
        { perspective: 500 },
        { translateZ: guildCardTranslateZAnimation.value },
        { translateX: guildCardTranslateXAnimation.value },
        { translateY: guildCardTranslateYAnimation.value },
      ],

      shadowColor: `rgba(10, 37, 64, ${interpolate(
        guildCardTranslateZAnimation.value,
        [0, 100],
        [0.08, 0.16],
        Extrapolate.EXTEND
      )})`,
      shadowRadius: interpolate(
        guildCardTranslateZAnimation.value,
        [0, 100],
        [12, 24],
        Extrapolate.EXTEND
      ),
      shadowOffset: {
        width: interpolate(
          guildCardTranslateZAnimation.value,
          [0, 100],
          [0, 18],
          Extrapolate.EXTEND
        ),
        height: interpolate(
          guildCardTranslateZAnimation.value,
          [0, 100],
          [0, 24],
          Extrapolate.EXTEND
        ),
      },
    }
  }, [])

  const eventCardStyle = useAnimatedStyle(() => {
    return {
      borderRadius: 32,
      overflow: 'hidden',
      zIndex: 2,
      marginVertical: 12,
      // width: 'calc(100% + 60px)',

      transform: [
        { perspective: 500 },
        { translateZ: eventCardTranslateZAnimation.value },
        { translateX: eventCardTranslateXAnimation.value },
      ],
      shadowColor: `rgba(10, 37, 64, ${interpolate(
        guildCardTranslateZAnimation.value,
        [0, 100],
        [0.08, 0.16],
        Extrapolate.EXTEND
      )})`,
      shadowRadius: interpolate(
        guildCardTranslateZAnimation.value,
        [0, 100],
        [12, 24],
        Extrapolate.EXTEND
      ),
      shadowOffset: {
        width: interpolate(
          guildCardTranslateZAnimation.value,
          [0, 100],
          [0, -9],
          Extrapolate.EXTEND
        ),
        height: interpolate(
          guildCardTranslateZAnimation.value,
          [0, 100],
          [0, 24],
          Extrapolate.EXTEND
        ),
      },
    }
  }, [])

  // console.log('BREAKPOINT', breakpoint)

  return (
    <>
      <Breakpoint display={[0, 1]}>
        <View style={{ position: 'relative', width: '100%' }}>
          <View
            ref={gradientContainerRef}
            style={{
              // overflow: 'hidden',
              width: '100%',
              // shadowColor: 'rgba(10, 37, 64, 0.08)',
              // shadowRadius: 12,
              backgroundImage:
                'url(https://ik.imagekit.io/guild/filler-gradient-background_yqr7Ync65.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              boxShadow:
                '0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)',
            }}
            onLayout={() => {
              if (gradient) {
                gradient.resize()
              }
            }}
          >
            <ErrorBoundary
              fallback={
                <View
                  style={{
                    height: selectBreakpointToken(breakpoint, [590, 650, 950]),
                  }}
                />
              }
            >
              <HomeScreenGradient
                getGradient={(gradient) => setGradient(gradient)}
                height={590}
                $gtXs={{
                  height: 650,
                }}
                $gtSm={{
                  height: 950,
                }}
              />
            </ErrorBoundary>
          </View>

          <View
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              paddingTop: 100,
              paddingHorizontal: 40,
              mixBlendMode: 'overlay',
            }}
          >
            <Text
              size={[13, 15]}
              lineHeight={[12, 14]}
              variant='homeHeroMain'
              textAlign='center'
            >
              <span className='notranslate'>ELEVATE</span>
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: selectBreakpointToken(breakpoint, [150, 190]),
              left: selectBreakpointToken(breakpoint, [40, 105]),
              mixBlendMode: 'overlay',
              transform: [
                {
                  rotate: '20deg',
                },
              ],
            }}
          >
            <Text
              size={[7, 8, 9, 10]}
              lineHeight={[6, 7, 8, 9]}
              variant='markerExtras'
              textAlign='center'
            >
              I
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: selectBreakpointToken(breakpoint, [170, 225]),
              left: selectBreakpointToken(breakpoint, [50, 70]),
              mixBlendMode: 'overlay',
            }}
          >
            <Text
              size={[14, 15]}
              lineHeight={[13, 14]}
              variant='markerExtras'
              textAlign='center'
            >
              v
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',

              width: '100%',
              height: '100%',

              top: `calc(80px + ${selectBreakpointToken(breakpoint, [
                tokensContext.lineHeight[12] + 4,
                tokensContext.lineHeight[14] - 4,
              ])}px)`,
            }}
          >
            <Stack alignItems='center' gap={10}>
              <View
                style={{
                  paddingHorizontal: 40,
                }}
              >
                <Stack alignItems='center' gap={10}>
                  <Text size={[9, 11]} variant='homeHero' textAlign='center'>
                    <span className='notranslate'>Your Community</span>
                  </Text>

                  <Stack gap={1} alignItems='center'>
                    <Text variant='homeHeroCopy' size={6} textAlign='center'>
                      Guild is the platform for communities.
                    </Text>

                    <Text variant='homeHeroCopy' size={6} textAlign='center'>
                      No matter the shape.
                    </Text>
                  </Stack>

                  <CallToActionButton />
                </Stack>
              </View>

              <View ref={guildCardRef} style={{ maxWidth: 400 }}>
                <Animated.View style={cardStyle}>
                  <GuildCard
                    guild={data.nodeBySlugId}
                    limitDescriptionLines={8}
                    expand
                  />
                </Animated.View>
              </View>
            </Stack>
          </View>
        </View>
      </Breakpoint>

      <Screen variant='unauthenticatedHomeScreen'>
        <Stack gap={20} alignItems='center'>
          <Stack
            gap={selectBreakpointToken(breakpoint, [48, 48, 120, 100])}
            variant='fullWidth'
          >
            <Breakpoint display={[2, 3, 4]}>
              <View
                style={{
                  position: 'relative',
                  width: '100%',
                  // overflow: 'hidden',
                }}
              >
                <View
                  ref={gradientContainerRef}
                  style={{
                    borderRadius: 24,
                    overflow: 'hidden',
                    width: '100%',
                    // shadowColor: 'rgba(10, 37, 64, 0.08)',
                    // shadowRadius: 12,
                    backgroundImage:
                      'url(https://ik.imagekit.io/guild/filler-gradient-background_yqr7Ync65.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow:
                      '0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)',
                  }}
                  onLayout={() => {
                    if (gradient) {
                      gradient.resize()
                    }
                  }}
                >
                  <ErrorBoundary
                    fallback={
                      <View
                        style={{
                          height: selectBreakpointToken(
                            breakpoint,
                            [590, 650, 950]
                          ),
                        }}
                      />
                    }
                  >
                    <HomeScreenGradient
                      getGradient={(gradient) => setGradient(gradient)}
                      height={590}
                      $gtXs={{
                        height: 650,
                      }}
                      $gtSm={{
                        height: 950,
                      }}
                    />
                  </ErrorBoundary>
                </View>

                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    // height: '100%',
                    paddingTop: 120,
                    paddingHorizontal: 120,
                    mixBlendMode: 'overlay',
                  }}
                >
                  <Text
                    size={[8, 12, 15, 16]}
                    lineHeight={[7, 11, 14, 15]}
                    variant='homeHeroMain'
                    textAlign='center'
                  >
                    <span className='notranslate'>ELEVATE</span>
                  </Text>
                </View>

                <View
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: selectBreakpointToken(breakpoint, [0, 0, 210, 240]),
                    left: selectBreakpointToken(breakpoint, [0, 0, 80, 130]),
                    mixBlendMode: 'overlay',
                    transform: [
                      {
                        rotate: '20deg',
                      },
                    ],
                  }}
                >
                  <Text
                    size={[7, 8, 9, 10]}
                    lineHeight={[6, 7, 8, 9]}
                    variant='markerExtras'
                    textAlign='center'
                  >
                    I
                  </Text>
                </View>

                <View
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: selectBreakpointToken(breakpoint, [0, 0, 225, 255]),
                    left: selectBreakpointToken(breakpoint, [0, 0, 85, 110]),
                    mixBlendMode: 'overlay',
                  }}
                >
                  <Text
                    size={[8, 12, 16, 17]}
                    lineHeight={[7, 11, 15, 16]}
                    variant='markerExtras'
                    textAlign='center'
                  >
                    v
                  </Text>
                </View>

                <View
                  style={{
                    position: 'absolute',

                    width: '100%',
                    height: '100%',

                    top: `calc(120px + ${selectBreakpointToken(breakpoint, [
                      tokensContext.lineHeight[7],
                      tokensContext.lineHeight[11],
                      tokensContext.lineHeight[14],
                      tokensContext.lineHeight[15],
                    ])}px - 12px)`,
                  }}
                >
                  <Stack alignItems='center' gap={10}>
                    <View
                      style={{
                        paddingHorizontal: 120,
                      }}
                    >
                      <Stack alignItems='center' gap={10}>
                        <Text
                          size={[8, 10, 11, 12]}
                          lineHeight={[7, 9, 10, 11]}
                          variant='homeHero'
                          textAlign='center'
                        >
                          <span className='notranslate'>Your Community</span>
                        </Text>

                        <Stack gap={1} alignItems='center'>
                          <Text
                            variant='homeHeroCopy'
                            size={7}
                            textAlign='center'
                          >
                            Guild is the platform for communities.
                          </Text>

                          <Text
                            variant='homeHeroCopy'
                            size={7}
                            textAlign='center'
                          >
                            No matter the shape.
                          </Text>
                        </Stack>

                        <CallToActionButton />
                      </Stack>
                    </View>

                    <View
                      ref={guildCardRef}
                      style={{ maxWidth: '100%', paddingHorizontal: 60 }}
                    >
                      <Animated.View
                        // style={[{ paddingHorizontal: 40 }, cardStyle]}
                        style={bigCardStyle}
                      >
                        <Grid gap={8}>
                          <Column span={[1, 1, 2, 1]}>
                            <Animated.View style={guildCardStyle}>
                              <GuildCard
                                guild={data.nodeBySlugId}
                                limitDescriptionLines={6}
                                expand
                              />
                            </Animated.View>
                          </Column>

                          <Breakpoint display={[1, 2, 3, 4]}>
                            <Column span={[1, 1, 3, 2]}>
                              <Stack
                                gap={10}
                                // gap={selectBreakpointToken(
                                //   breakpoint,
                                //   [6, 6, 8, 6]
                                // )}
                              >
                                <Stack
                                  direction='horizontal'
                                  gap={4}
                                  alignItems='center'
                                >
                                  <Text size={4} variant='cardHeading'>
                                    Events
                                  </Text>

                                  <HorizontalLine variant='cardHeading' />
                                </Stack>

                                <Animated.View style={eventCardStyle}>
                                  {/* <Card variant='homeScreen'> */}
                                  <EventItem
                                    padding='$6'
                                    paddingBottom='$5'
                                    borderRadius='$8'
                                    boxShadow='0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)'
                                    event={
                                      data.nodeBySlugId?.upcomingEvents
                                        ?.edges[0]?.node
                                        ? data.nodeBySlugId.upcomingEvents
                                            .edges[0].node
                                        : data.nodeBySlugId?.pastEvents
                                            ?.edges[0].node
                                    }
                                    forceVertical={breakpoint === 2}
                                    limitPresentations={1}
                                    query={data}
                                  />
                                  {/* </Card> */}
                                </Animated.View>

                                <Stack
                                  direction='horizontal'
                                  gap={4}
                                  alignItems='center'
                                >
                                  <Text size={4} variant='cardHeading'>
                                    Presentations
                                  </Text>

                                  <HorizontalLine variant='cardHeading' />
                                </Stack>

                                <Animated.View
                                  style={{
                                    transform: [
                                      { perspective: 500 },
                                      { translateZ: 0 },
                                    ],
                                  }}
                                >
                                  <Card variant='homeScreen'>
                                    <PresentationItem
                                      presentation={
                                        data.nodeBySlugId?.upcomingPresentations
                                          ?.edges[0]?.node
                                          ? data.nodeBySlugId
                                              ?.upcomingPresentations?.edges[0]
                                              .node
                                          : data.nodeBySlugId?.pastPresentations
                                              ?.edges[0].node
                                      }
                                      numberOfLines={4}
                                      openAsModal={true}
                                    />
                                  </Card>
                                </Animated.View>
                              </Stack>

                              {/* <EventCard
                                event={data.eventBySlug}
                                showOrganizer
                                showPresentationsOrAttendees
                                expand
                              /> */}
                            </Column>

                            {/* <Breakpoint display={[3, 4]}>
                              <Column>
                                <PresentationCard
                                  presentation={data.presentationBySlug}
                                  numberOfLines={8}
                                  expand
                                />
                              </Column>
                            </Breakpoint> */}
                          </Breakpoint>
                        </Grid>
                      </Animated.View>
                    </View>
                  </Stack>
                </View>
              </View>
            </Breakpoint>

            <Stack gap={6}>
              <Text size={6} variant='hero' textAlign='center'>
                Loved by communities everywhere
              </Text>

              <Stack direction='horizontal' gap={10} justifyContent='center'>
                {data.rankedGuilds?.edges
                  .slice(0, selectBreakpointToken(breakpoint, [3, 4, 4, 5]))
                  .map(({ node, cursor }) => {
                    return (
                      <Link to={`/${node.slugId}`} key={cursor}>
                        <Stack gap={2} alignItems='center'>
                          <GuildPrimaryPhoto guild={node} size={15} />

                          <Text
                            size={4}
                            textAlign='center'
                            variant='homeScreenSelectedGuilds'
                          >
                            {node.name} {node.type === 'NETWORK' && 'Network'}
                          </Text>
                        </Stack>
                      </Link>
                    )
                  })}
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={20} variant='fullWidth'>
            <Stack alignItems='center'>
              <View
                style={{
                  marginTop: 150,
                }}
              >
                <Text
                  size={[9, 10, 11]}
                  lineHeight={[8, 9, 10]}
                  variant='marker'
                  textAlign='center'
                >
                  EVERYTHING YOU NEED
                </Text>
              </View>
              <Text size={[7, 8]} variant='heading' textAlign='center'>
                to scale to new heights
              </Text>
            </Stack>

            <Stack gap={9}>
              <Breakpoint display={[0, 1, 2]}>
                <HomeScreenEventsCard />

                <HomeScreenPresentationsCard query={data} />
              </Breakpoint>

              <Breakpoint display={[3, 4]}>
                <Grid gap={9}>
                  <Column span={3}>
                    <HomeScreenEventsCard />
                  </Column>

                  <Column span={2}>
                    <HomeScreenPresentationsCard query={data} />
                  </Column>
                </Grid>
              </Breakpoint>

              <HomeScreenGuildNetworksCard />
            </Stack>
          </Stack>

          <Stack gap={20} variant='fullWidth'>
            <Stack alignItems='center'>
              <View
                style={{
                  marginTop: 150,
                }}
              >
                <Text
                  size={[9, 10, 11]}
                  lineHeight={[8, 9, 10]}
                  variant='marker'
                  textAlign='center'
                >
                  ONE CONNECTED NETWORK
                </Text>
              </View>
              <Text size={[7, 8]} variant='heading' textAlign='center'>
                with every feature working together
              </Text>
            </Stack>

            <HomeScreenFeaturesCard />
          </Stack>

          {/* <Stack gap={20} variant='fullWidth'>
            <Stack alignItems='center'>
              <View
                style={{
                  background:
                    'linear-gradient(89.31deg, #9A16C8 25.81%, #F880C1 52.29%, #FF8642 76.81%)',

                  WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  marginTop: 150,
                }}
              >
                <Text
                  size={[9, 10]}
                  lineHeight={[8, 9]}
                  variant='marker'
                  textAlign='center'
                >
                  INSPIRED BY PEOPLE
                </Text>
              </View>
              <Text size={[7, 8]} variant='heading' textAlign='center'>
                who fuel communities everywhere
              </Text>
            </Stack>

            <Stack gap={6}>
              <Card variant='homeScreen'>
                <Text>Person 1</Text>
              </Card>

              <Grid gap={6}>
                <Column span={1}>
                  <Card variant='homeScreen'>
                    <Text>Person 2</Text>
                  </Card>
                </Column>

                <Column span={2}>
                  <Card variant='homeScreen'>
                    <Text>Person 3</Text>
                  </Card>
                </Column>
              </Grid>
            </Stack>
          </Stack> */}

          <Stack alignItems='center' gap={6}>
            <Text size={[7, 8]} variant='heading' textAlign='center'>
              Ready to join in?
            </Text>

            <CallToActionButton />
          </Stack>

          <Stack />
        </Stack>
      </Screen>
    </>
  )
}

const CallToActionButton: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Button
      variant='homeHero'
      hoverVariant='homeHeroHover'
      onPress={() => {
        navigate('/sign-up', { state: { backgroundLocation: location } })
      }}
    >
      <Text variant='homeHeroCopy' size={[6, 7]}>
        Let's go
      </Text>
    </Button>
  )
}
